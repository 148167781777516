import {
  DatapointMap,
  TimeSeries,
  convertToTimeseries,
  datapointsToMap,
  generateIdealBinSize,
  useDataApi,
} from "api/data";
import { useThingsApi } from "api/ingestion/things";
import { MeterDetail, Pin } from "components";
import { DeviceAlerts } from "components/alerts/DeviceAlerts";
import MetricCard from "components/alerts/MetricCard";
import { RawChart } from "components/charts";
import { mapboxConfig } from "configs/mapbox";
import { useSelectedDevice } from "context/SelectedDeviceContext";
import { useSelectedSimulation } from "context/SelectedSimulationContext";
import { useSelectedTimeRange } from "context/SelectedTimeRangeContext.tsx";
import { typeToLabel } from "utils/typeToLabel";

import { useEffect, useState } from "react";
import Map, { Marker } from "react-map-gl";

import { ReactComponent as ControlsIcon } from "images/icons/deviceDetailTabs/controls.svg";
import { ReactComponent as DetailsIcon } from "images/icons/deviceDetailTabs/details.svg";
import { ReactComponent as SecurityIcon } from "images/icons/deviceDetailTabs/security.svg";

import { useAuth } from "../../context/AuthContext";
import ControlsTab from "./ControlsTab";
import EditDeviceModal from "./EditDeviceModal";
import SecurityTab from "./SecurityTab";

enum TabType {
  DETAILS = "details",
  SECURITY = "security",
  CONTROLS = "controls",
}

interface DeviceDetailProps {
  selectedDevice: Thing;
  setSelectedDevice?: (device: Thing | null) => void;
  placeType: string;
}

const DeviceDetail = ({
  selectedDevice,
  setSelectedDevice = () => {},
  placeType,
}: DeviceDetailProps) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const isMeter = selectedDevice?.thingType === "Meter";
  const { getThingFromSite, getThingFromFleet } = useThingsApi();

  const refreshDeviceData = async () => {
    try {
      const placeId = selectedDevice.siteId || selectedDevice.fleetId;
      const updatedDevice =
        selectedDevice.placeType === "Site"
          ? await getThingFromSite(placeId, selectedDevice.thingId)
          : await getThingFromFleet(placeId, selectedDevice.thingId);

      setSelectedDevice(updatedDevice);
    } catch (error) {
      console.error("Error refreshing device data:", error);
    }
  };

  return (
    <div
      className={`origin-right pr-6 h-screen overflow-y-auto bg-white lg:relative transition-transform drop-shadow-xl lg:drop-shadow-none ${
        selectedDevice
          ? "translate-x-0 lg:ml-4 px-2 right-0 scale-x-100 min-w-[500px] lg:w-2/5 z-20"
          : "pl-4 translate-x-full scale-x-0 w-0"
      }`}
    >
      {selectedDevice && (
        <>
          {!isMeter && (
            <ChargerOrBatteryDetails
              {...selectedDevice}
              placeType={placeType}
              setSelectedDevice={setSelectedDevice}
              onEditClick={() => setIsEditModalOpen(true)}
            />
          )}
          {isMeter && (
            <MeterDetail
              {...selectedDevice}
              placeType={placeType}
              setSelectedDevice={setSelectedDevice}
            />
          )}

          <EditDeviceModal
            isOpen={isEditModalOpen}
            onClose={() => setIsEditModalOpen(false)}
            onDeviceEdited={async () => {
              await refreshDeviceData();
              setIsEditModalOpen(false);
            }}
            device={selectedDevice}
            placeType={selectedDevice.placeType}
            placeId={selectedDevice?.siteId || selectedDevice?.fleetId}
          />
        </>
      )}
    </div>
  );
};

const ChargerOrBatteryDetails = ({
  placeType,
  siteId,
  thingId,
  thingType,
  thingName,
  thingDescription,
  longitude,
  latitude,
  model,
  thingManufacturerId,
  setSelectedDevice,
  onEditClick,
}: {
  placeType: string;
  siteId: string;
  thingId: string;
  thingType: string;
  thingName: string;
  thingDescription: string;
  longitude: number;
  latitude: number;
  model: string;
  thingManufacturerId: string;
  setSelectedDevice: (device: Thing | null) => void;
  onEditClick: () => void;
}) => {
  const isCharger = thingType == "Charger"; // TODO: this should be more robust
  const { start, end } = useSelectedTimeRange();
  const { user, permissions } = useAuth();
  const [activeTab, setActiveTab] = useState<TabType>(TabType.DETAILS);
  const { selectedDevice } = useSelectedDevice();
  const [stats, setStats] = useState<DatapointMap>({});
  const [timeseries, setTimeseries] = useState<TimeSeries | null>(undefined);
  const [timeseriesForSignalStrength, setTimeseriesForSignalStrength] =
    useState<TimeSeries | null>(undefined);
  const { simulationId } = useSelectedSimulation();
  const { getDataForThing, getSummaryForThing } = useDataApi();

  useEffect(() => {
    const fetchStats = async () => {
      const stats = await getSummaryForThing(
        placeType ?? "site",
        siteId,
        thingId,
        start,
        end,
        simulationId,
      );
      setStats(datapointsToMap(stats));
    };

    const fetchTimeseries = async () => {
      const [binValue, binUnit] = generateIdealBinSize(start, end);
      getDataForThing(
        placeType ?? "site",
        siteId,
        thingId,
        start,
        end,
        binUnit,
        binValue,
        simulationId,
      )
        .then(convertToTimeseries)
        .then(setTimeseries)
        .catch((e) => {
          setTimeseries(null);
          console.error(e);
        });
    };

    const fetchTimeseriesForSignalStrength = async () => {
      getDataForThing(
        placeType ?? "site",
        siteId,
        thingId,
        start,
        end,
        "m",
        5,
        simulationId,
      )
        .then(convertToTimeseries)
        .then(setTimeseriesForSignalStrength)
        .catch((e) => {
          setTimeseriesForSignalStrength(null);
          console.error(e);
        });
    };

    if (!user) return;

    fetchStats();
    fetchTimeseries();
    fetchTimeseriesForSignalStrength();
  }, [placeType, siteId, thingId, start, end, isCharger, simulationId]);

  const closeButton = (
    <span
      onClick={() => setSelectedDevice(null)}
      className="text-blue50 float-right cursor-pointer"
    >
      &times;
    </span>
  );

  const statsView = (
    <div className="p-4 elevation-1 rounded-md flex flex-col gap-4">
      <h3 className="text-heading3 text-space50">Stats</h3>
      <div className="grid grid-cols-2 gap-4">
        {Object.values(stats).map((stat) => (
          <MetricCard
            key={stat.type}
            metricCardStat={{
              value: stat.value.toFixed(2) || null,
              label: typeToLabel(stat.type),
              units: stat.unit,
              trend: 0,
            }}
          />
        ))}
      </div>
    </div>
  );

  const alertsView = (
    <div className="p-4 elevation-1 rounded-md flex flex-col gap-4">
      <DeviceAlerts
        placeType={placeType}
        placeId={siteId}
        thingId={thingId}
        start={start}
        end={end}
        simulationId={simulationId}
      />
    </div>
  );

  const map = (
    <div className="p-4 w-full elevation-1 rounded-md flex overflow-hidden h-[300px]">
      <Map
        initialViewState={{
          longitude,
          latitude,
          zoom: 12,
        }}
        viewState={{
          width: "100%",
          height: "100%",
        }}
        mapStyle="mapbox://styles/mapbox/light-v11"
        mapboxAccessToken={mapboxConfig.token}
      >
        <Marker longitude={longitude} latitude={latitude} anchor="center">
          <Pin color={"#5EA15F"} id={thingName.slice(-1)} />
        </Marker>
      </Map>
    </div>
  );

  const utilizationChart = (
    <div className="p-4 elevation-1 rounded-md flex flex-col gap-4">
      <h3 className="text-heading3 text-space50">Utilization Chart</h3>
      <RawChart timeseries={timeseries} colors={["#f2aa3c", "#ab5fb3"]} />
    </div>
  );

  // Function to extract signal strength data only
  const filterSignalStrengthData = (data) => {
    if (data) {
      return {
        start: data?.start,
        end: data?.end,
        types: ["Signal"],
        units: ["dBm"],
        values: data.values.map((reading) => ({
          Signal: reading.Signal,
          time: reading.time,
        })),
        summary: {
          Signal: data.summary.Signal,
          time: data.summary.time,
        },
      };
    }
  };

  const signalStrengthChart = (
    <div className="p-4 elevation-1 rounded-md flex flex-col gap-4">
      <h3 className="text-heading3 text-space50">Signal Strength Chart</h3>
      <RawChart
        timeseries={filterSignalStrengthData(timeseriesForSignalStrength)}
        colors={["#f2aa3c", "#ab5fb3"]}
      />
    </div>
  );

  const rawDetails = (
    <div className="p-4 elevation-1 rounded-md flex flex-col">
      <h3 className="text-heading3 text-space50 mb-4">Raw Details</h3>
      <p className="text-caption text-space70">Site: {siteId}</p>
      <p className="text-caption text-space70">
        Location: {latitude}, {longitude}
      </p>
      <p className="text-caption text-space70">Type Name: {thingType}</p>
      <p className="text-caption text-space70">Name: {thingName}</p>
      <p className="text-caption text-space70">Model: {model}</p>
      <p className="text-caption text-space70">
        Manufature ID: {thingManufacturerId}
      </p>
    </div>
  );

  const renderActionButtons = () => {
    if (permissions.includes("write:ingest_things")) {
      return (
        <button
          onClick={onEditClick}
          className="px-3.5 py-2 rounded-full border border-space80 justify-start items-center gap-1 cursor-pointer hover:bg-gray95 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue90 focus:ring-blue90 flex self-start"
        >
          <div className="text-space70 text-xs font-medium leading-[14px]">
            Edit Device
          </div>
        </button>
      );
    }
    return null;
  };

  const propertiesData = (
    <div className="p-4 elevation-1 rounded-md flex flex-col">
      <h3 className="text-heading3 text-space50 mb-4">Properties</h3>
      {Object.keys(selectedDevice.properties).length > 0 ? (
        Object.entries(selectedDevice.properties).map(([key, value]) => (
          <p key={key} className="text-caption text-space70">
            {key}: {value}
          </p>
        ))
      ) : (
        <p className="text-caption text-space70">No properties configured</p>
      )}
    </div>
  );

  const renderTabContent = () => {
    switch (activeTab) {
      case TabType.DETAILS:
        return (
          <div className="flex flex-col gap-4">
            {renderActionButtons()}
            {statsView}
            {signalStrengthChart}
            {alertsView}
            {propertiesData}
            {map}
            {utilizationChart}
            {rawDetails}
          </div>
        );
      case TabType.SECURITY:
        return (
          <SecurityTab
            placeType={placeType}
            siteId={siteId}
            thingId={thingId}
          />
        );
      case TabType.CONTROLS:
        return thingType === "SwapStation" ? (
          <ControlsTab
            placeType={placeType}
            siteId={siteId}
            thingId={thingId}
            thingManufacturerId={thingManufacturerId}
          />
        ) : (
          <div className="p-4 text-gray-500">
            Controls are only available for SwapStations
          </div>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    return () => {
      setActiveTab(TabType.DETAILS); // Reset to details tab when unmounting
    };
  }, []);

  return (
    <div className="flex flex-col gap-4 py-4">
      <div className="relative">
        <div className="items-center gap-2 text-gray-500">
          <p className="text-xs uppercase">{placeType}</p>
          <h4 className="text-lg font-medium text-gray-900">
            <span className="text-gray-500">{thingType}</span> / {thingName}
          </h4>
          {thingDescription && (
            <p className="text-sm text-gray-600 mt-1">{thingDescription}</p>
          )}
        </div>
        <button
          onClick={() => setSelectedDevice(null)}
          className="absolute top-0 right-0 text-gray-500 hover:text-gray-700"
        >
          &times;
        </button>
      </div>

      <div className="rounded-lg p-1">
        <nav className="grid grid-cols-3 gap-1">
          <button
            onClick={() => setActiveTab(TabType.DETAILS)}
            className={`flex flex-col items-center py-3 px-2 rounded-lg transition-colors ${
              activeTab === TabType.DETAILS
                ? "bg-slate-200 text-slate-600"
                : "text-gray-500 hover:text-gray-700 hover:bg-gray-50"
            }`}
          >
            <DetailsIcon
              className={`w-5 h-5 mb-1 ${
                activeTab === TabType.DETAILS
                  ? "text-blue-600"
                  : "text-gray-500"
              }`}
            />
            <span className="text-sm">Details</span>
          </button>
          <button
            onClick={() => setActiveTab(TabType.SECURITY)}
            className={`flex flex-col items-center py-3 px-2 rounded-lg transition-colors ${
              activeTab === TabType.SECURITY
                ? "bg-slate-200 text-slate-600"
                : "text-gray-500 hover:text-gray-700 hover:bg-gray-50"
            }`}
          >
            <SecurityIcon
              className={`w-5 h-5 mb-1 ${
                activeTab === TabType.SECURITY
                  ? "text-blue-600"
                  : "text-gray-500"
              }`}
            />
            <span className="text-sm">Security</span>
          </button>
          <button
            onClick={() => setActiveTab(TabType.CONTROLS)}
            className={`flex flex-col items-center py-3 px-2 rounded-lg transition-colors ${
              activeTab === TabType.CONTROLS
                ? "bg-slate-200 text-slate-600"
                : "text-gray-500 hover:text-gray-700 hover:bg-gray-50"
            }`}
          >
            <ControlsIcon
              className={`w-5 h-5 mb-1 ${
                activeTab === TabType.CONTROLS
                  ? "text-blue-600"
                  : "text-gray-500"
              }`}
            />
            <span className="text-sm">Controls</span>
          </button>
        </nav>
      </div>

      <div className="mt-4">{renderTabContent()}</div>
    </div>
  );
};

export default DeviceDetail;
