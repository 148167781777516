import { useDataApi } from "api/data";
import { useThingsApi } from "api/ingestion/things";

import React, { useEffect, useState } from "react";

import { ReactComponent as DeleteIcon } from "images/icons/delete.svg";
import { ReactComponent as WarningIcon } from "images/icons/warning.svg";

interface PropertyChanges {
  updates: Record<string, string>;
  deletes: string[];
}

const EditDeviceModal = ({
  isOpen,
  onClose,
  onDeviceEdited,
  device,
  placeType,
  placeId,
}) => {
  const [formData, setFormData] = useState({
    thingName: "",
    thingDescription: "",
    address: "",
    thingManufacturerId: "",
    longitude: "",
    latitude: "",
    altitude: "",
    integrationId: "",
    levelId: "",
    primaryGroup: "",
  });

  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [availableProperties, setAvailableProperties] = useState<
    string[] | null
  >(null);
  const [properties, setProperties] = useState<Record<string, string>>({});
  const [propertyChanges, setPropertyChanges] = useState<PropertyChanges>({
    updates: {},
    deletes: [],
  });

  const { getThingPropertyKeys } = useDataApi();
  const {
    editThing,
    editThingPrimaryGroup,
    updateThingProperty,
    deleteThingProperty,
  } = useThingsApi();

  const [newPropertyKey, setNewPropertyKey] = useState("");
  const [newPropertyValue, setNewPropertyValue] = useState("");

  useEffect(() => {
    if (device) {
      setFormData({
        thingName: device.thingName || "",
        thingDescription: device.thingDescription || "",
        address: device.address || "",
        thingManufacturerId: device.thingManufacturerId || "",
        longitude: device.longitude ?? "",
        latitude: device.latitude ?? "",
        altitude: device.altitude ?? "",
        integrationId: device.integrationId || "",
        levelId: device.levelId || "",
        primaryGroup: device.primaryGroup || "",
        placeType: placeType,
      });
      setProperties(device.properties || {});

      getThingPropertyKeys().then((keys) => {
        setAvailableProperties(keys[device.thingType] || []);
      });
    }
  }, [device, placeType]);

  useEffect(() => {
    if (isOpen && device) {
      if (device.properties) {
        setProperties(device.properties);
      }

      setPropertyChanges({
        updates: {},
        deletes: [],
      });
    }
  }, [isOpen, device]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateForm = () => {
    let valid = true;
    let newErrors = {};

    if (!formData.thingName) {
      newErrors.thingName = "Thing Name is required";
      valid = false;
    }

    if (!formData.thingDescription) {
      newErrors.thingDescription = "Description is required";
      valid = false;
    }

    if (formData.latitude === "") {
      newErrors.latitude = "Latitude is required";
      valid = false;
    } else {
      const lat = parseFloat(formData.latitude);
      if (isNaN(lat) || lat < -90 || lat > 90) {
        newErrors.latitude = "Latitude must be between -90 and 90";
        valid = false;
      }
    }

    if (formData.longitude === "") {
      newErrors.longitude = "Longitude is required";
      valid = false;
    } else {
      const lng = parseFloat(formData.longitude);
      if (isNaN(lng) || lng < -180 || lng > 180) {
        newErrors.longitude = "Longitude must be between -180 and 180";
        valid = false;
      }
    }

    if (formData.altitude === "") {
      newErrors.altitude = "Altitude is required";
      valid = false;
    } else {
      const alt = parseFloat(formData.altitude);
      if (isNaN(alt)) {
        newErrors.altitude = "Altitude must be a number";
        valid = false;
      }
    }

    if (placeType === "Site" && !formData.levelId) {
      newErrors.levelId = "Level ID is required for Site devices";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handlePropertyChange = (key: string, value: string) => {
    setProperties((prev) => ({
      ...prev,
      [key]: value,
    }));

    setPropertyChanges((prev) => ({
      ...prev,
      updates: {
        ...prev.updates,
        [key]: value,
      },
    }));
  };

  const handleDeleteProperty = (key: string) => {
    if (
      !window.confirm(
        `Are you sure you want to delete the property "${key}"?\nChanges to properties may impact metric calculations.`,
      )
    ) {
      return;
    }

    setProperties((prev) => {
      const newProps = { ...prev };
      delete newProps[key];
      return newProps;
    });

    setPropertyChanges((prev) => ({
      updates: {
        ...prev.updates,
        ...(delete prev.updates[key] && {}),
      },
      deletes: [...new Set([...prev.deletes, key])],
    }));
  };

  const handleAddProperty = () => {
    if (!newPropertyKey || !newPropertyValue) {
      return;
    }

    setProperties((prev) => ({
      ...prev,
      [newPropertyKey]: newPropertyValue,
    }));

    setPropertyChanges((prev) => ({
      ...prev,
      updates: {
        ...prev.updates,
        [newPropertyKey]: newPropertyValue,
      },
    }));

    setNewPropertyKey("");
    setNewPropertyValue("");
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMessage("");

    if (!validateForm()) {
      return;
    }

    try {
      const thingResult = await editThing(
        placeType,
        placeId,
        device.thingId,
        formData,
      );

      if (!thingResult.success) {
        setErrorMessage(thingResult.error);
        return;
      }

      if (
        formData.primaryGroup &&
        formData.primaryGroup !== device.primaryGroup
      ) {
        const groupResult = await editThingPrimaryGroup(
          placeType,
          placeId,
          device.thingId,
          formData.primaryGroup,
        );

        if (!groupResult.success) {
          setErrorMessage(groupResult.error);
          return;
        }
      }

      const hasPropertyChanges =
        propertyChanges.deletes.length > 0 ||
        Object.keys(propertyChanges.updates).length > 0;

      if (hasPropertyChanges) {
        for (const key of propertyChanges.deletes) {
          const result = await deleteThingProperty(
            placeType,
            placeId,
            device.thingId,
            key,
          );

          if (!result.success) {
            setErrorMessage(result.error);
            return;
          }
        }

        for (const [key, value] of Object.entries(propertyChanges.updates)) {
          const result = await updateThingProperty(
            placeType,
            placeId,
            device.thingId,
            key,
            value,
          );

          if (!result.success) {
            setErrorMessage(result.error);
            return;
          }
        }
      }

      onClose();
      await onDeviceEdited();
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return isOpen ? (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
      <div className="bg-white p-6 rounded-lg shadow-lg w-[700px] max-h-[90vh] overflow-y-auto mx-4">
        <h2 className="text-xl font-semibold mb-4">Edit Device</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="thingName"
            >
              Name*
            </label>
            <input
              type="text"
              name="thingName"
              id="thingName"
              value={formData.thingName}
              onChange={handleChange}
              className={`border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.thingName ? "border-red-500" : ""
              }`}
            />
            {errors.thingName && (
              <p className="text-red-500 text-xs italic">{errors.thingName}</p>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="thingDescription"
            >
              Description*
            </label>
            <input
              type="text"
              name="thingDescription"
              id="thingDescription"
              value={formData.thingDescription}
              onChange={handleChange}
              className={`border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.thingDescription ? "border-red-500" : ""
              }`}
            />
            {errors.thingDescription && (
              <p className="text-red-500 text-xs italic">
                {errors.thingDescription}
              </p>
            )}
          </div>

          <div className="grid grid-cols-3 gap-4">
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="latitude"
              >
                Latitude*
              </label>
              <input
                type="number"
                name="latitude"
                id="latitude"
                value={formData.latitude}
                onChange={handleChange}
                className={`border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  errors.latitude ? "border-red-500" : ""
                }`}
              />
              {errors.latitude && (
                <p className="text-red-500 text-xs italic">{errors.latitude}</p>
              )}
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="longitude"
              >
                Longitude*
              </label>
              <input
                type="number"
                name="longitude"
                id="longitude"
                value={formData.longitude}
                onChange={handleChange}
                className={`border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  errors.longitude ? "border-red-500" : ""
                }`}
              />
              {errors.longitude && (
                <p className="text-red-500 text-xs italic">
                  {errors.longitude}
                </p>
              )}
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="altitude"
              >
                Altitude*
              </label>
              <input
                type="number"
                name="altitude"
                id="altitude"
                value={formData.altitude}
                onChange={handleChange}
                className={`border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  errors.altitude ? "border-red-500" : ""
                }`}
              />
              {errors.altitude && (
                <p className="text-red-500 text-xs italic">{errors.altitude}</p>
              )}
            </div>
          </div>

          {placeType === "Site" && (
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="levelId"
              >
                Level ID*
              </label>
              <input
                type="text"
                name="levelId"
                id="levelId"
                value={formData.levelId}
                onChange={handleChange}
                className={`border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  errors.levelId ? "border-red-500" : ""
                }`}
              />
              {errors.levelId && (
                <p className="text-red-500 text-xs italic">{errors.levelId}</p>
              )}
            </div>
          )}

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="primaryGroup"
            >
              Primary Group
            </label>
            <input
              type="text"
              name="primaryGroup"
              id="primaryGroup"
              value={formData.primaryGroup}
              onChange={handleChange}
              className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>

          {Object.keys(properties).length > 0 && (
            <>
              <h3 className="text-gray-700 text-sm font-bold mb-2">
                Properties
              </h3>
              <div className="mb-4 overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/3">
                        Property
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/2">
                        Value
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {Object.entries(properties).map(([key, value]) => (
                      <tr key={key} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {key}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <input
                            type="text"
                            value={value}
                            onChange={(e) =>
                              handlePropertyChange(key, e.target.value)
                            }
                            className="w-full border border-gray-300 rounded-md shadow-sm px-3 py-2 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <button
                            type="button"
                            onClick={() => handleDeleteProperty(key)}
                            className="text-red-500 hover:text-red-700 flex items-center justify-center w-full transition-colors duration-150"
                          >
                            <DeleteIcon className="h-5 w-5" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}

          {(Object.keys(propertyChanges.updates).length > 0 ||
            propertyChanges.deletes.length > 0) && (
            <div className="mb-4 text-red-600 text-sm flex items-center gap-2">
              <WarningIcon className="h-5 w-5" />
              Warning: Changes to properties may impact metric calculations
            </div>
          )}

          <div className="bg-gray-50 p-4 rounded-lg">
            <h4 className="text-sm font-medium mb-3">Add New Property</h4>
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-5">
                <select
                  value={newPropertyKey}
                  onChange={(e) => setNewPropertyKey(e.target.value)}
                  className="w-full border rounded px-3 py-2"
                  disabled={!availableProperties}
                >
                  <option value="">
                    {!availableProperties
                      ? "Loading properties..."
                      : availableProperties.length === 0
                        ? "No properties available"
                        : "Select Property"}
                  </option>
                  {availableProperties
                    ?.filter((key) => !Object.keys(properties).includes(key))
                    .map((key) => (
                      <option key={key} value={key}>
                        {key}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-span-5">
                <input
                  type="text"
                  value={newPropertyValue}
                  onChange={(e) => setNewPropertyValue(e.target.value)}
                  placeholder="Value"
                  className="w-full border rounded px-3 py-2"
                />
              </div>
              <div className="col-span-2">
                <button
                  type="button"
                  onClick={handleAddProperty}
                  disabled={!newPropertyKey || !newPropertyValue}
                  className="w-full px-3 py-2 bg-blue-500 text-white rounded disabled:bg-blue-300"
                >
                  Add
                </button>
              </div>
            </div>
          </div>

          {errorMessage && (
            <p className="text-red-500 text-xs italic mb-4">{errorMessage}</p>
          )}

          <div className="flex justify-end space-x-4">
            <button
              type="submit"
              className="px-3.5 py-2 rounded-full border border-space80 bg-blue-600 justify-end items-center gap-1 cursor-pointer flex"
            >
              <div className="text-white text-xs font-medium leading-[14px]">
                Save Changes
              </div>
            </button>
            <button
              type="button"
              onClick={onClose}
              className="px-3.5 py-2 rounded-full border border-space80 justify-end items-center gap-1 cursor-pointer flex"
            >
              <div className="text-space70 text-xs font-medium leading-[14px]">
                Cancel
              </div>
            </button>
          </div>
        </form>
      </div>
    </div>
  ) : null;
};

export default EditDeviceModal;
