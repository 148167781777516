import React, { useState, useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const Transaction = () => {
  const [tableSearchTerm, setTableSearchTerm] = useState('');

  const transactions = [
    {
      id: 'XX123456',
      userId: 'US987654',
      site: 'HUB_00J234',
      removedBatteryId: 'BAT_R98765',
      installedBatteryId: 'BAT_I12345',
      chargeState: '92%',
      revenue: '345 Rp',
      date: '08/17/2022',
      time: '14:25:32',
      paymentMethod: 'Credit Card'
    },
    {
      id: 'XX789012',
      userId: 'US456789',
      site: 'HUB_00K567',
      removedBatteryId: 'BAT_R54321',
      installedBatteryId: 'BAT_I67890',
      chargeState: '87%',
      revenue: '420 Rp',
      date: '08/18/2022',
      time: '09:15:47',
      paymentMethod: 'Debit Card'
    },
    {
      id: 'XX345678',
      userId: 'US123789',
      site: 'HUB_00L890',
      removedBatteryId: 'BAT_R13579',
      installedBatteryId: 'BAT_I24680',
      chargeState: '95%',
      revenue: '380 Rp',
      date: '08/19/2022',
      time: '16:40:18',
      paymentMethod: 'Mobile Wallet'
    },
    {
      id: 'XX901234',
      userId: 'US567890',
      site: 'HUB_00M123',
      removedBatteryId: 'BAT_R24680',
      installedBatteryId: 'BAT_I13579',
      chargeState: '89%',
      revenue: '400 Rp',
      date: '08/20/2022',
      time: '11:30:55',
      paymentMethod: 'Cash'
    },
    {
      id: 'XX567890',
      userId: 'US234567',
      site: 'HUB_00N456',
      removedBatteryId: 'BAT_R11111',
      installedBatteryId: 'BAT_I22222',
      chargeState: '91%',
      revenue: '360 Rp',
      date: '08/21/2022',
      time: '13:20:40',
      paymentMethod: 'Credit Card'
    },
    {
      id: 'XX234567',
      userId: 'US890123',
      site: 'HUB_00P789',
      removedBatteryId: 'BAT_R33333',
      installedBatteryId: 'BAT_I44444',
      chargeState: '88%',
      revenue: '390 Rp',
      date: '08/22/2022',
      time: '10:05:22',
      paymentMethod: 'Debit Card'
    },
    {
      id: 'XX890123',
      userId: 'US345678',
      site: 'HUB_00Q012',
      removedBatteryId: 'BAT_R55555',
      installedBatteryId: 'BAT_I66666',
      chargeState: '93%',
      revenue: '410 Rp',
      date: '08/23/2022',
      time: '15:50:33',
      paymentMethod: 'Mobile Wallet'
    },
    {
      id: 'XX456789',
      userId: 'US901234',
      site: 'HUB_00R345',
      removedBatteryId: 'BAT_R77777',
      installedBatteryId: 'BAT_I88888',
      chargeState: '86%',
      revenue: '370 Rp',
      date: '08/24/2022',
      time: '08:45:10',
      paymentMethod: 'Credit Card'
    },
    {
      id: 'XX012345',
      userId: 'US678901',
      site: 'HUB_00S678',
      removedBatteryId: 'BAT_R99999',
      installedBatteryId: 'BAT_I00000',
      chargeState: '90%',
      revenue: '430 Rp',
      date: '08/25/2022',
      time: '17:30:05',
      paymentMethod: 'Cash'
    },
    {
      id: 'XX678901',
      userId: 'US012345',
      site: 'HUB_00T901',
      removedBatteryId: 'BAT_R12121',
      installedBatteryId: 'BAT_I23232',
      chargeState: '94%',
      revenue: '385 Rp',
      date: '08/26/2022',
      time: '12:10:15',
      paymentMethod: 'Debit Card'
    }
  ];

  const filteredTransactions = useMemo(() => {
    return transactions.filter(transaction => 
      Object.values(transaction).some(value => 
        value.toString().toLowerCase().includes(tableSearchTerm.toLowerCase())
      )
    );
  }, [transactions, tableSearchTerm]);

  const SummarySection = () => {
    const summaryData = [
      { value: filteredTransactions.length, label: 'Transactions' },
      { value: filteredTransactions.reduce((sum, t) => sum + parseInt(t.revenue), 0), unit: 'Rp', label: 'Revenue' },
      { value: new Set(filteredTransactions.map(t => t.userId)).size, unit: 'Users', label: 'Unique Active Customers' }
    ];

    return (
      <div className="bg-white rounded-lg shadow-sm p-4 mb-6">
        <h2 className="text-sm font-semibold mb-4">Summary</h2>
        <div className="grid grid-cols-3 gap-4">
          {summaryData.map((item, index) => (
            <div key={index} className={`${index !== 0 ? 'border-l border-gray-200 pl-4' : ''}`}>
              <div className="flex items-baseline">
                <span className="text-2xl font-bold text-gray-800">{item.value}</span>
                {item.unit && <span className="ml-1 text-sm text-gray-500">{item.unit}</span>}
              </div>
              <p className="text-sm text-gray-500">{item.label}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const TransactionChart = () => {
    const chartData = transactions.map(t => ({
      date: t.date,
      revenue: parseInt(t.revenue)
    }));

    return (
      <div className="bg-white rounded-lg shadow-sm p-4 mb-6">
        <h2 className="text-lg font-semibold mb-4">Transaction Revenue</h2>
        <ResponsiveContainer width="100%" height={200}>
          <BarChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="revenue" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  return (
    <div className="w-full min-h-screen bg-gray-100 p-4">
      <div className="w-full bg-yellow-50 rounded-lg shadow-sm p-4 mb-6">
        <p className="text-space50 text-heading3">
          Demo Environment - Synthetic Financial Data Only
        </p>
      </div>
      <div className="flex justify-between items-center pt-4 pb-5">
        <div className="text-heading1 text-space50">Transaction History</div>
        <button
          className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
        >
          Export CSV
        </button>
      </div>
      
      {/* Summary Section */}
      <SummarySection />

      {/* Transaction Chart */}
      <TransactionChart />

      {/* Transactions Table */}
      <div className="bg-white rounded-lg shadow-sm overflow-hidden">
        <div className="p-4 flex justify-between items-center">
          <h2 className="text-lg font-semibold">Transactions</h2>
          <div className="relative w-1/3">
            <input
              type="text"
              placeholder="Search"
              className="w-full p-2 pl-8 rounded border"
              onChange={(e) => setTableSearchTerm(e.target.value)}
            />
            <svg className="w-4 h-4 absolute left-2.5 top-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
            </svg>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className="bg-gray-50">
              <tr className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                <th className="px-6 py-3">Transaction ID</th>
                <th className="px-6 py-3">User ID</th>
                <th className="px-6 py-3">Site</th>
                <th className="px-6 py-3">Removed Battery ID</th>
                <th className="px-6 py-3">Installed Battery ID</th>
                <th className="px-6 py-3">Installed Battery Charge State</th>
                <th className="px-6 py-3">Revenue (Rp)</th>
                <th className="px-6 py-3">Date</th>
                <th className="px-6 py-3">Time</th>
                <th className="px-6 py-3">Payment Method</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredTransactions.map((transaction) => (
                <tr key={transaction.id}>
                  <td className="px-6 py-4 whitespace-nowrap">{transaction.id}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{transaction.userId}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{transaction.site}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{transaction.removedBatteryId}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{transaction.installedBatteryId}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{transaction.chargeState}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{transaction.revenue}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{transaction.date}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{transaction.time}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{transaction.paymentMethod}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Transaction;