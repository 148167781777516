import { DataResponse, useDataApi } from "api/data";
import { Thing, useThingsApi } from "api/ingestion/things";
import { useSelectedDevice } from "context/SelectedDeviceContext";
import { Dayjs } from "dayjs";

import React, { useEffect, useState } from "react";
import { IoMdBatteryCharging } from "react-icons/io";
import { MdOutlinePower } from "react-icons/md";

export const AlertRow = ({
  alert,
  onSelect,
}: {
  alert: Alert;
  onSelect?: (thingId: string) => void;
}) => {
  const { thingId, time, type, value, unit } = alert;
  const icon =
    type === "batteryAlarm" ? (
      <IoMdBatteryCharging fontSize={20} />
    ) : (
      // else "stationAlarm"
      <MdOutlinePower fontSize={20} />
    );

  const timestamp = new Date(time).toLocaleString();

  return (
    <div
      className={`p-2 bg-yellow50 rounded flex flex-row gap-4 text-white text-body ${
        onSelect ? "cursor-pointer hover:bg-yellow60" : ""
      }`}
      onClick={() => onSelect?.(thingId)}
    >
      <div>{icon}</div>
      <div className="flex-row flex-1">
        <div className="flex-1">
          {type} {value} {unit}
        </div>
        <div className="text-xs opacity-70">{timestamp}</div>
        <div className="text-xs opacity-70">{thingId}</div>
      </div>
    </div>
  );
};

export const PlaceAlerts = ({
  placeType,
  placeId,
  start,
  end,
  simulationId,
}: {
  placeType: string;
  placeId: string;
  start: Dayjs;
  end: Dayjs;
  simulationId: string | null;
}) => {
  const { getAlertsForPlace } = useDataApi();
  const [alerts, setAlerts] = useState<Alert[] | undefined>(undefined);
  const [things, setThings] = useState<Thing[]>([]);

  const { setSelectedDevice } = useSelectedDevice();
  const { getThingsFromPlace } = useThingsApi();
  useEffect(() => {
    getAlertsForPlace(placeType, placeId, start, end, simulationId)
      .then(flattenAlerts)
      .then(setAlerts);
  }, [placeType, placeId, start, end, simulationId]);

  useEffect(() => {
    // pre-load thing details for each alert
    getThingsFromPlace(placeType, placeId).then(setThings);
  }, [placeType, placeId]);

  const handleOnClick = (thingId: string) => {
    console.log("thingId", thingId);
    console.log("things", things);
    const device = things.find((thing) => thing.thingId === thingId);
    console.log("device", device);
    setSelectedDevice(device);
  };

  return (
    <div className="overflow-auto h-full">
      <p className="text-space50 text-heading3 mb-4">Alerts</p>
      <div className="flex flex-col gap-2 pt-2">
        {!alerts ? (
          <div className="text-caption text-center text-space70 py-4">
            Loading...
          </div>
        ) : alerts.length > 0 ? (
          alerts.map((alert, idx) => (
            <AlertRow alert={alert} key={idx} onSelect={handleOnClick} />
          ))
        ) : (
          <div className="text-caption text-center text-space70 py-4">
            No alerts for selected timeframe
          </div>
        )}
      </div>
    </div>
  );
};

export type Alert = {
  thingId: string;
  time: string;
  type: string;
  value: string;
  unit: string;
};

export const flattenAlerts = (alerts: DataResponse[]): Alert[] => {
  // for each alert, get the time and first dataPoints value
  return alerts
    .map((alert) => {
      const dataPoint = alert.dataPoints[0];

      if (!dataPoint) {
        return null;
      }

      return {
        thingId: alert.groupBy,
        time: alert.time,
        type: dataPoint.type,
        value: dataPoint.value,
        unit: dataPoint.unit,
      };
    })
    .filter((e) => e !== null);
};
