import { usePlacesApi } from "api/ingestion/places.ts";
import { PlaceTile } from "components/places/PlaceTile";
import { useAuth } from "context/AuthContext";

import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import AddFleetModal from "./AddFleetModal";
import AddSiteModal from "./AddSiteModal";
import { MapView } from "./mapView";

const LocationSelection = ({ locationType }) => {
  const [locations, setLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSiteModalOpen, setIsSiteModalOpen] = useState(false);
  const [isFleetModalOpen, setIsFleetModalOpen] = useState(false);
  const [currentType, setCurrentType] = useState(null);

  const { permissions } = useAuth();
  const { getSites, getFleets, addSite, addFleet } = usePlacesApi();

  const fetchLocations = () => {
    setIsLoading(true);
    const fetchFunc = locationType === "site" ? getSites : getFleets;
    fetchFunc()
      .then((data) => {
        setLocations(data);
        setIsLoading(false);
        setCurrentType(locationType);
      })
      .catch((error) => {
        console.error(`Error fetching ${locationType} data:`, error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchLocations();
  }, [locationType]);

  const handleOpenSiteModal = () => setIsSiteModalOpen(true);
  const handleCloseSiteModal = () => setIsSiteModalOpen(false);
  const handleOpenFleetModal = () => setIsFleetModalOpen(true);
  const handleCloseFleetModal = () => setIsFleetModalOpen(false);

  const handleAddSite = async (siteData) => {
    try {
      const result = await addSite(siteData);
      if (result.success) {
        return {
          success: true,
          siteId: result.siteId,
        };
      } else {
        return { success: false, error: result.error };
      }
    } catch (error) {
      return { success: false, error: { message: error.message } };
    }
  };

  const handleAddFleet = async (fleetData) => {
    try {
      const result = await addFleet(fleetData);
      if (result.success) {
        return {
          success: true,
          fleetId: result.fleetId,
        };
      } else {
        console.error("Failed to add fleet:", result.error);
        return { success: false, error: result.error };
      }
    } catch (error) {
      console.error("Error adding fleet:", error);
      return { success: false, error: error.message };
    }
  };

  const renderLocationTile = (location) => {
    return <PlaceTile place={location} placeType={locationType} />;
  };

  const getLocationId = (location) => {
    return locationType === "site" ? location.siteId : location.fleetId;
  };

  if (isLoading || currentType !== locationType) {
    return (
      <div className="flex w-full min-h-screen items-center justify-center">
        <div className="text-lg font-medium text-gray-600">
          Loading {locationType} data...
        </div>
      </div>
    );
  }

  const renderAddButton = () => {
    if (permissions.includes("write:ingest_things")) {
      return locationType === "site" ? (
        <button
          className="px-3.5 py-2 rounded-full justify-end items-center gap-1 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue90 focus:ring-blue90 flex border border-space80 hover:bg-gray95 text-space70"
          onClick={handleOpenSiteModal}
        >
          + Add Site
        </button>
      ) : (
        <button
          className="px-3.5 py-2 rounded-full justify-end items-center gap-1 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue90 focus:ring-blue90 flex border border-space80 hover:bg-gray95 text-space70"
          onClick={handleOpenFleetModal}
        >
          + Add Fleet
        </button>
      );
    }
    return null;
  };

  const renderContent = () => {
    if (locationType === "site") {
      return (
        <div className="flex flex-col lg:flex-row gap-4 mr-4 mb-4">
          <div className="w-full lg:w-1/2">
            {locations.map((location) => (
              <NavLink
                key={getLocationId(location)}
                to={`/${locationType}s/${getLocationId(location)}`}
              >
                {renderLocationTile(location)}
              </NavLink>
            ))}
          </div>
          <div className="w-full lg:w-1/2">
            <div className="bg-white rounded-md shadow border border-zinc-300 h-screen">
              <MapView locations={locations} />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mr-4">
          {locations.map((location) => (
            <NavLink
              key={getLocationId(location)}
              to={`/${locationType}s/${getLocationId(location)}`}
              className="block w-full"
            >
              {renderLocationTile(location)}
            </NavLink>
          ))}
        </div>
      );
    }
  };

  return (
    <div className="min-h-screen grow">
      <div className="flex w-full min-h-screen">
        <div className="flex-1 px-4">
          <div className="flex justify-start items-center pt-4 pb-5 gap-4">
            <div className="text-heading1 text-space50">
              {locationType === "site" ? "Sites" : "Fleets"} / All
            </div>
            {renderAddButton()}
          </div>
          <div className="mr-0">{renderContent()}</div>
        </div>
      </div>
      {locationType === "site" && (
        <AddSiteModal
          isOpen={isSiteModalOpen}
          onClose={handleCloseSiteModal}
          onAddSite={handleAddSite}
        />
      )}
      {locationType === "fleet" && (
        <AddFleetModal
          isOpen={isFleetModalOpen}
          onClose={handleCloseFleetModal}
          onAddFleet={handleAddFleet}
        />
      )}
    </div>
  );
};

export default LocationSelection;
