import { DeviceTile } from "components";

interface DeviceGroup {
  [key: string]: any[];
}

const formatTitle = (type: string): string => {
  return type
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, str => str.toUpperCase());
};

const Section = ({ title, devices }) => {
  if (devices.length === 0) return null;
  
  return (
    <>
      <p className="text-heading3 text-space50">{formatTitle(title)}</p>
      <div className="flex flex-wrap gap-4">
        {devices.map((device) => (
          <DeviceTile device={device} key={device.thingName} />
        ))}
      </div>
    </>
  );
};

const SkyportsChargerStatus = ({ devices }: { devices: DeviceGroup }) => {
  return (
    <div>
      <div className="flex flex-col gap-2 p-3">
        {Object.entries(devices).map(([type, deviceList]) => (
          <Section 
            key={type} 
            title={type}
            devices={deviceList}
          />
        ))}
      </div>
    </div>
  );
};

export default SkyportsChargerStatus;