import { DataResponse, useDataApi } from "api/data";
import { Dayjs } from "dayjs";

import React, { useEffect, useState } from "react";
import { IoMdBatteryCharging } from "react-icons/io";
import { MdOutlinePower } from "react-icons/md";

import { Alert, AlertRow, flattenAlerts } from "./PlaceAlerts";

export const DeviceAlerts = ({
  placeType,
  placeId,
  thingId,
  start,
  end,
  simulationId,
}: {
  placeType: string;
  placeId: string;
  thingId: string;
  start: Dayjs;
  end: Dayjs;
  simulationId: string | null;
}) => {
  const { getAlertsForThing } = useDataApi();
  const [alerts, setAlerts] = useState<Alert[] | undefined>(undefined);

  useEffect(() => {
    getAlertsForThing(placeType, placeId, thingId, start, end, simulationId)
      .then(flattenAlerts)
      .then(setAlerts);
  }, [placeType, placeId, thingId, start, end, simulationId]);

  return (
    <div className="overflow-auto h-full">
      <p className="text-space50 text-heading3 mb-4">Alerts</p>
      <div className="flex flex-col gap-2 pt-2">
        {!alerts ? (
          <div className="text-caption text-center text-space70 py-4">
            Loading...
          </div>
        ) : alerts.length > 0 ? (
          alerts.map((alert, idx) => <AlertRow alert={alert} key={idx} />)
        ) : (
          <div className="text-caption text-center text-space70 py-4">
            No alerts for selected timeframe
          </div>
        )}
      </div>
    </div>
  );
};
